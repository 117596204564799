import { default as _91_46_46_46route_93MLFNaXzaeIMeta } from "/builds/webcontact/rheinau/pages/[...route].vue?macro=true";
import { default as indexDb91kriHFqMeta } from "/builds/webcontact/rheinau/pages/index.vue?macro=true";
export default [
  {
    name: "route",
    path: "/:route(.*)*",
    meta: _91_46_46_46route_93MLFNaXzaeIMeta || {},
    component: () => import("/builds/webcontact/rheinau/pages/[...route].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/webcontact/rheinau/pages/index.vue").then(m => m.default || m)
  }
]